import axios from "axios";

import { SET_NOTIFICATION, LOADING_NOTIFICATION } from "./types";

import Env from "../env";

export const getNotification = () => (dispatch, getState) => {
  dispatch({ type: LOADING_NOTIFICATION });

  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  axios
    .get(`${Env.apiUrl}/api/public-notifications/active-notification/`, config)
    .then((res) => {
      dispatch({
        type: SET_NOTIFICATION,
        payload: res.data || null,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_NOTIFICATION,
        payload: null,
      });
    });
};
