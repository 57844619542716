import Loader from "react-loaders";
import {connect} from "react-redux";
import Error404 from "../../pages/Auth/Error404";
import PrivateRoute from "../../common/PrivateRoute";
import React, {Suspense, lazy, Fragment} from "react";
import {Route, Redirect, withRouter} from "react-router-dom";

import {loadUser} from "../../actions/auth";
import {ToastContainer} from "react-toastify";
// import { getToken } from "../../config/firebase";
import "react-toastify/dist/ReactToastify.min.css";
// import { authService } from "../../services/AuthService";
import {UserRoleValues} from "../../assets/values/constants";
import {getNotification} from "../../actions/notification";

const OrderPages = lazy(() => import("../../pages/Orders"));
const UserPages = lazy(() => import("../../pages/Users"));
const DevelopmentPages = lazy(() => import("../../pages/Development"));
const DashboardPages = lazy(() => import("../../pages/Dashboard"));
const ReportPages = lazy(() => import("../../pages/Reports"));
const AuthPages = lazy(() => import("../../pages/Auth"));
const SupportPages = lazy(() => import("../../pages/Support"));
const CustomersPages = lazy(() => import("../../pages/Customers"));
const ReturnPages = lazy(() => import("../../pages/Returns"));
const FinancePages = lazy(() => import("../../pages/Finance"));
const TestPages = lazy(() => import("../../pages/Tests"));
const SettingPages = lazy(() => import("../../pages/Settings"));

const AppMain = ({auth}) => {
    // if (auth.user) {
    //   getToken((fcmToken) => {
    //     authService.registerFCMToken(fcmToken, auth.token);
    //   });
    // }

    return (
        <Fragment>
            {/* Users */}
            <Suspense
                fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader active type="ball-pulse-rise"/>
                            </div>
                        </div>
                    </div>
                }
            >
                <PrivateRoute exact path="/">
                    {auth.user && auth.user.role === UserRoleValues.SUPPLIER_ADMIN ? <Redirect to="/dashboard"/> :
                        <Redirect to="/orders/orders"/>}
                </PrivateRoute>
                <PrivateRoute path="/orders" component={OrderPages}/>
                <PrivateRoute path="/users" component={UserPages}/>
                <PrivateRoute path="/dashboard" component={DashboardPages}/>
                {/* <PrivateRoute path="/support" component={SupportPages} /> */}
                <PrivateRoute path="/reports" component={ReportPages}/>
                <PrivateRoute path="/customers" component={CustomersPages}/>
                <PrivateRoute path="/finance" component={FinancePages}/>
                <PrivateRoute path="/returns" component={ReturnPages}/>
                <PrivateRoute path="/development" component={DevelopmentPages}/>
                <PrivateRoute path="/tests" component={TestPages}/>
                <PrivateRoute path="/settings" component={SettingPages}/>
                <Route path="/auth" component={AuthPages}/>
                <Route path="/error" component={Error404}/>
            </Suspense>
            <ToastContainer
                position="bottom-center"
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    loadUser: () => dispatch(loadUser()),
    getNotification: () => dispatch(getNotification()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AppMain));
