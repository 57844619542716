import {
  LOADING_NOTIFICATION,
  SET_NOTIFICATION,
} from "../actions/types";

const initialState = {
  notification: null,
  isLoading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        isLoading: false,
        notification: action.payload,
      };
    default:
      return state;
  }
}
