import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../../actions/auth'

// Layout

class Error404 extends React.Component {
    constructor(props) {
        super(props)
    }

    render = () => {
        return (
            <Fragment>
                <div className="h-100 bg-plum-plate bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="8" className="mx-auto app-login-box">
                        <div className="app-logo-inverse mx-auto" />
                        <h1 className="text-center text-light mt-5">404 - Page not found.</h1>
                        </Col>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default Error404
