import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "../reducers";
import thunk from "redux-thunk";

const middlewares = [thunk];

export default function configureStore() {
  // if (process.env.NODE_ENV === `development`) {
  //   const { logger } = require(`redux-logger`);
  //
  //   middlewares.push(logger);
  // }
  return createStore(
    combineReducers({
      ...reducers,
    }),
    {},
    composeWithDevTools(applyMiddleware(...middlewares))
  );
}
