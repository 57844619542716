import "./polyfills";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-web-vector-icons/fonts";
import { PusherProvider } from "react-pusher-hoc";
import Pusher from "pusher-js";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter as Router } from "react-router-dom";
import Geocode from "react-geocode";
import "./assets/base.scss";
import Main from "./pages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import { loadUser } from "./actions/auth";
import { getNotification } from "./actions/notification";
import { consts } from "./assets/values/constants";

Geocode.setApiKey(consts.GOOGLE_API_KEY);
Geocode.enableDebug();

const store = configureStore();
const rootElement = document.getElementById("root");

const pusherClient = new Pusher("ff8dc06ce53831298a33", {
  cluster: "eu",
});

const renderApp = (Component) => {
  store.dispatch(loadUser());
  store.dispatch(getNotification());
  ReactDOM.render(
    <Provider store={store}>
      <PusherProvider value={pusherClient}>
        <Router>
          <Component />
        </Router>
      </PusherProvider>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./pages/Main", () => {
    const NextApp = require("./pages/Main").default;
    renderApp(NextApp);
  });
}

serviceWorker.unregister();
